import { gql } from "@apollo/client";

export default gql`
fragment mediaItemFragment on MediaItem {
  id
  title
  altText
  caption
  sizes
  srcSet
  sourceUrl
}
`;