import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarLoaded, toggleSidebarExpanded, setLogoAnimate, setSidebarReady } from "../redux/reducers/baseData";

import PrimaryMenu from './Sidebar/PrimaryMenu';
import SocialMenu from "./Sidebar/SocialMenu";

import '../assets/img/logo.min.css';
import { ReactComponent as Logo } from '../assets/img/logo.svg';
import { FaChevronRight } from 'react-icons/fa';

function Sidebar() {
  const siteName = useSelector(({ baseData }) => baseData.site.title);
  const dispatch = useDispatch();
  
  const sidebarLoaded = useSelector(({ baseData }) => baseData.sidebar.loaded);
  const sidebarExpanded = useSelector(({ baseData }) => baseData.sidebar.expanded);
  
  const logoAnimate = useSelector(({ baseData }) => baseData.sidebar.logoAnimate);
  const primaryMenuReady = useSelector(({ baseData }) => baseData.sidebar.ready.primaryMenu);
  const socialMenuReady = useSelector(({ baseData }) => baseData.sidebar.ready.socialMenu);
  
  useEffect(() => {
    if(siteName && primaryMenuReady && socialMenuReady) {
      dispatch(setSidebarLoaded());
      setTimeout(() => dispatch(setLogoAnimate(true)), 250);
    }
  }, [siteName, primaryMenuReady, socialMenuReady]);
  
  return (
    <div className={`flex relative min-w-[350px] max-w-[350px] transition-[margin] duration-250 ${sidebarLoaded ? (sidebarExpanded ? "ml-0" : "ml-[-340px] hover:ml-0") : "ml-[-340px]"} shadow-lg shadow-magenta-900`}>
      <div className="flex w-full relative border-r-10 border-magenta-500">
        <div className="absolute top-0 left-0 w-full h-full z-0 sidebarBackground animated"></div>
        <div className="flex flex-col justify-between w-full h-full text-slate-200 z-10">
          <header>
            <div className="flex mt-8 justify-center">
              <NavLink to={'/'} className="w-3/4 transition-colors duration-250">
                <Logo className={`w-full h-auto ${logoAnimate ? "animate" : ""}`} />
              </NavLink>
            </div>
            <PrimaryMenu onLoaded={() => dispatch(setSidebarReady({key: 'primaryMenu'}))} />
          </header>
          <footer>
            <SocialMenu onLoaded={() => dispatch(setSidebarReady({key: 'socialMenu'}))} />
            <div className="flex justify-center py-3">
              <NavLink to={'/'} className="text-sm transition-colors duration-250 hover:text-magenta-500">
                &copy;{new Date().getFullYear()} {siteName}
              </NavLink>
            </div>
          </footer>
        </div>
      </div>
      <div className="flex absolute top-[90px] py-2 px-1 bg-magenta-500 transition-position duration-250 z-10" style={{left: sidebarLoaded ? "calc(100% - 10px)" : "calc(100% - 64px)"}}>
        <button className="text-white transition-colors duration-250 hover:text-magenta-900" onClick={() => dispatch(toggleSidebarExpanded())}>
          <FaChevronRight className={`w-6 h-6 transition-all duration-250 hover:w-8 hover:h-8 ${sidebarExpanded ? '-scale-x-100' : 'scale-x-100'}`} />
        </button>
      </div>
    </div>
  );
}

export default Sidebar;