import { gql } from "@apollo/client";
import menuFields from "../fragments/menuFields";

export default gql`
query GetPrimaryMenu {
  menu(id: "primary", idType: LOCATION) {
    menuItems {
      nodes {
        ...menuFields
        childItems {
          nodes {
            ...menuFields
            childItems {
              nodes {
                ...menuFields
                childItems {
                  nodes {
                    ...menuFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
${menuFields}
`;