import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import constructPage from "../../utils/constructPage";
import GetPost from "../../graphQL/queries/post/GetPost";

import { FaUser, FaClock } from 'react-icons/fa';
import Image from "../../Components/Image";
import Scrollbar from "react-custom-scrollbars-2";

function Post({ uri }) {
  const [getPost, { loading, error, data, startPolling }] = useLazyQuery(GetPost);
  
  const titleRef = React.createRef();
  const [titleStuck, setTitleStuck] = useState(false);
  const getScrollParent = (node) => {
    if (node == null) {
      return null;
    }
    
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }
  
  useEffect(() => {
    if(uri) {
      getPost({
        variables: {
          id: uri
        }
      });
    }
  }, [uri]);
  
  useEffect(() => {
    if(data) {
      console.log('data', data);
      startPolling(15 * 60e3);
    }
  }, [data]);
  
  useEffect(() => {
    if(titleRef?.current !== null) {
      const cachedRef = titleRef.current;
      const observer = new IntersectionObserver(
        ([e]) => setTitleStuck(e.intersectionRatio < 1),
        { root: getScrollParent(cachedRef), rootMargin: '0px 0px 50% 0px', threshold: [1] }
      );
      observer.observe(cachedRef);
  
      return () => observer.unobserve(cachedRef);
    }
  }, [titleRef]);
  
  if(loading) return (<div>Loading...</div>);
  
  return data?.post ? (
    <Scrollbar
      className="tracksOnHover"
      renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, height: '10px' }} className="horizontalTrack w-full bottom-0 z-50" />}
      renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '10px' }} className="verticalTrack h-full right-0 z-50" />}
      renderThumbHorizontal={({ style, ...props}) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
      renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
    >
      {data.post.featuredImage.node.sourceUrl ? (
        <React.Fragment>
          <Image {...data.post.featuredImage.node} />
          <div ref={titleRef} className={`flex flex-col sticky -top-[1px] py-4 px-10 -mt-[100px] justify-center text-white inset-x-0 transition-colors duration-250 ${titleStuck ? 'bg-magenta-500 bg-opacity-100' : 'bg-magenta-700 bg-opacity-60'} z-[9]`}>
            <h1 className="uppercase font-bold text-2xl">{data.post.title}</h1>
            <div className="flex text-sm items-center">
              <span className="inline-flex items-center"><FaUser className="inline-flex w-4 h-4 mr-1" /> {data.post.author.node.name}</span>
              {/*<span className="inline-flex items-center ml-4">{'Category?'}</span>*/}
              <span className="inline-flex items-center ml-4"><FaClock className="inline-flex w-4 h-4 mr-1" /> {new Date(`${data.post.dateGmt}Z`).toLocaleString()}</span>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      <div className="flex flex-col relative p-10 bg-white">
        {constructPage(data.post)}
      </div>
    </Scrollbar>
  ) : null;
}

export default Post;