import { gql } from "@apollo/client";

export default gql`
query GetBaseData {
  generalSettings {
    url
    title
    description
  }
}
`;