import React from "react";

function Image({ title, sourceUrl, srcSet, sizes, altText, noTitle, noSizes }) {
  return (
    <img
      className="w-full aspect-[16/9] object-cover"
      title={!noTitle ? title : null}
      src={sourceUrl}
      srcSet={srcSet}
      sizes={!noSizes ? sizes : null}
      alt={altText}
    />
  );
}

export default Image;