import React from "react";

const isDev = false;

const Gallery = React.lazy(() => import('../Components/Gallery'));

const recursiveElementRender = (elementArray, key = "") => {
  let content = [];
  for (const [index, value] of elementArray.entries()) {
    const attrs = value.attributesJSON;
    const id = attrs?.anchor ? attrs.anchor : null;
    const cssClasses = attrs?.className ? attrs.className : "";
    const cssStyle = {};
    
    if(attrs?.textColor) cssStyle['color'] = `var(--${attrs.textColor})`;
    if(attrs?.backgroundColor) cssStyle['backgroundColor'] = `var(--${attrs.backgroundColor})`;
    if(attrs?.gradient) cssStyle['backgroundImage'] = `var(--gradient--${attrs.gradient})`;
    if(attrs?.style) {
      for(const groupKey in attrs.style) {
        switch (groupKey) {
          case 'color': {
            if(attrs.style[groupKey]?.text) cssStyle['color'] = attrs.style[groupKey].text;
            if(attrs.style[groupKey]?.gradient) cssStyle['backgroundImage'] = attrs.style[groupKey].gradient;
            break;
          }
          case 'typography': {
            for(const cssKey in attrs.style[groupKey]) {
              cssStyle[cssKey] = attrs.style[groupKey][cssKey];
            }
            break;
          }
        }
      }
    }
    
    switch (value.name) {
      case "core/gallery": {
        // TODO migrate gallery to srcset stuff!!!
        const images = value.innerBlocks.map((image) => {
          return {
            alt: image.attributesJSON.alt,
            title: image.attributesJSON.title,
            url: image.attributesJSON.url
          };
        });
        
        if(!images) continue;
        
        content.push(<React.Suspense key={`${key}${index}gallery`} fallback={<div>Loading...</div>}>
          <Gallery id={id} config={{spaceBetween: 50, slidesPerView: attrs.columns}} items={images} className={`flex relative ${cssClasses}`} />
        </React.Suspense>);
        break;
      }
      case "core/image": {
        const title = attrs?.title ? attrs.title : null;
        const alt = attrs?.alt ? attrs.alt : null;
        
        if(!attrs.url) continue;
        
        content.push(<img key={`${key}${index}image`} id={id} className={`w-full h-auto ${cssClasses}`} style={cssStyle} src={attrs.url} alt={alt} title={title} />);
        break;
      }
      case "core/group": {
        const TagName = attrs?.tagName ? attrs.tagName : null;
        
        if(!TagName && !value.innerBlocks) continue;
        
        content.push(<TagName key={`${key}${index}group`} id={id} className={`flex flex-col ${cssClasses}`} style={cssStyle}>{recursiveElementRender(value.innerBlocks, `${key}${index}groupItem`)}</TagName>);
        break;
      }
      case "core/heading": {
        const Tag = attrs?.level ? `h${attrs.level}` : null;
        
        if(!Tag && !attrs?.content) continue;
        
        content.push(<Tag key={`${key}${index}heading`} id={id} className={`${cssClasses}`} style={cssStyle}>{attrs.content}</Tag>);
        break;
      }
      case "core/columns": {
        if(!value.innerBlocks) continue;
        content.push(<div key={`${key}${index}columns`} id={id} className={`flex justify-around ${cssClasses}`} style={cssStyle}>{recursiveElementRender(value.innerBlocks, `${key}${index}column`)}</div>);
        break;
      }
      case "core/column": {
        if(!value.innerBlocks) continue;
        content.push(<div key={`${key}${index}columnItems`} id={id} className={`flex flex-col ${cssClasses}`} style={cssStyle}>{recursiveElementRender(value.innerBlocks, `${key}${index}columnItem`)}</div>);
        break;
      }
      case "core/paragraph": {
        if(!attrs?.content) continue;
        content.push(<p key={`${key}${index}paragraph`} id={id} className={`${cssClasses}`} style={cssStyle}>{attrs.content}</p>);
        break;
      }
      default: {
        if(isDev) console.log(index, value, value.innerBlocks);
        content.push(<div key={`${key}${index}`}>Sorry there bud, looks like something is misconfigured</div>);
        break;
      }
    }
  }
  return content;
}

export default (content) => {
  let pageStyle = "";
  if(isDev) console.log("== constructPage: Start == constructPage: Start == constructPage: Start == constructPage: Start == constructPage: Start == constructPage: Start == constructPage: Start =");
  if(isDev) console.log(content);
  
  // noinspection FallThroughInSwitchStatementJS
  if(content?.pageExtra?.pagestyle) {
    switch (content.pageExtra.pagestyle) {
      default:
        console.log(`might not have a case ${content.pageExtra.pagestyle}`);
      case "default": {
        pageStyle = "mx-10 py-10";
        break;
      }
      case "fullWidth": {
        pageStyle = "py-10";
        break;
      }
      case "fullScreen": {
        pageStyle = "";
        break;
      }
    }
  }
  
  let _content = [];
  if(content?.blocks) {
    _content.push(recursiveElementRender(content.blocks));
  } else {
    _content = <div>Sorry there bud, looks like something is misconfigured</div>;
  }
  
  if(isDev) console.log("== constructPage: End ==== constructPage: End ==== constructPage: End ==== constructPage: End ==== constructPage: End ==== constructPage: End ==== constructPage: End ===");
  return (<div className={`flex flex-col transition-margin duration-250 ${pageStyle}`}>{_content}</div>);
}