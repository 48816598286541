import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import GetPortfolioItem from "../../graphQL/queries/portfolio/GetPortfolioItem";
import GetMediaItems from "../../graphQL/queries/GetMediaItems";

import { FaTimes, FaHandPointRight, FaExternalLinkAlt, FaClock, FaTags } from "react-icons/fa";

import { Transition } from "@headlessui/react";
import Scrollbar from "react-custom-scrollbars-2";

const Gallery = React.lazy(() => import('../../Components/Gallery'));

// TODO portfolioSingle view
function PortfolioSingle({ uri }) {
  const [getPortfolioItem] = useLazyQuery(GetPortfolioItem);
  const [getMediaItems] = useLazyQuery(GetMediaItems);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [gallery, setGallery] = useState([]);
  const [autoplay, setAutoplay] = useState(true);
  const [visible, setVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [pageData, setPageData] = useState({
    title: "",
    date: "",
    dateGmt: "",
    dateEndGmt: "",
    description: "",
    highlights: [],
    links: [],
    tags: [],
  });
  
  useEffect(() => {
    if(uri) {
      getPortfolioItem({ variables: { id: uri } }).then((portfolioItem) => {
        const _PortfolioItemStartPolling = portfolioItem.startPolling;
        const _PortfolioItemStopPolling = portfolioItem.stopPolling;

        portfolioItem = portfolioItem?.data?.portfolioItem;

        if(portfolioItem === undefined || portfolioItem === null) return;

        // Resolve Page Data
        let pageData = {
          title: portfolioItem?.title,
          date: new Date(portfolioItem?.date),
          dateGmt: new Date(`${portfolioItem?.dateGmt}Z`),
          dateEndGmt: new Date(`${portfolioItem?.projectFulfillmentDate}T00:00:00Z`),
          description: portfolioItem?.projectDescription,
          highlights: [],
          links: [],
          tags: portfolioItem?.portfolioTags?.nodes.map((ele) => ele.name)
        };

        try {
          pageData.highlights = JSON.parse(portfolioItem?.projectHighlights);
          pageData.links = JSON.parse(portfolioItem?.projectLinks);
        } catch (e) {
          pageData.highlights = portfolioItem?.projectHighlights;
          pageData.links = portfolioItem?.projectLinks;
        }

        setPageData(pageData);

        // Resolve Gallery
        let projectGallery;
        try {
          projectGallery = JSON.parse(portfolioItem?.projectGallery);
        } catch (e) {
          projectGallery = portfolioItem?.projectGallery;
        }

        if(projectGallery === undefined) return;

        getMediaItems({ variables: { ids: projectGallery } }).then((mediaItems) => {
          let galleryItemList = [];
          mediaItems = mediaItems?.data?.mediaItems?.nodes;

          for (const mediaItemID of projectGallery) {
            let mediaItem = mediaItems.filter((ele) => parseInt(ele.mediaItemId) === parseInt(mediaItemID))[0];

            // TODO migrate gallery to srcset stuff!!!
            galleryItemList.push({
              alt: mediaItem.altText,
              sizes: mediaItem.sizes,
              srcset: mediaItem.srcset,
              title: mediaItem.title,
              url: mediaItem.sourceUrl
            });
          }
          setGallery(galleryItemList);

          setVisible(true);

          //_PortfolioItemStartPolling(15 * 60e3); // 15 minutes
        });
      });
    }
  }, []);

  useEffect(() => {
    console.log('pageData:', pageData);
  }, [pageData]);
  
  return (
      <Transition
          show={visible}
          appear={true}
          enter="duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="flex absolute top-0 left-0 w-full h-full bg-black z-[9999] transition-opacity ease-in-out opacity-0"
          afterEnter={() => {
            setSidebarVisible(true);
            setAutoplay(true);
          }}
          afterLeave={() => {
            navigate(`/${location.pathname.split("/").filter((ele) => ele !== "").slice(0, -1).join("/")}/`);
          }}
      >
        <div className={`flex relative ${sidebarVisible ? "w-2/3" : "w-full"} h-full transition-width duration-250`}>
          {gallery.length ? (
              <React.Suspense fallback={<div>Loading...</div>}>
                <Gallery config={{spaceBetween: 0, slidesPerView: 1}} items={gallery} className={`flex relative`} autoplay={autoplay} />
              </React.Suspense>
          ) : null}
          <button
              className="absolute top-0 right-0 p-4 transition-colors bg-opacity-25 bg-magenta-800 hover:bg-opacity-75 text-magenta-700 hover:text-magenta-500 z-10"
              onClick={() => {
                setSidebarVisible(false);
                setAutoplay(false);
                setTimeout(() => setVisible(false), 250);
              }}
          >
            <FaTimes className="w-8 h-8" />
          </button>
        </div>
        <div className={`flex w-1/3 ${sidebarVisible ? "mr-0" : "-mr-[33.5%]"} h-full bg-white transition-colors duration-250 border-l-10 border-magenta-700 hover:border-magenta-600`}>
          <Scrollbar
              className="tracksOnHover"
              renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, height: '10px' }} className="horizontalTrack w-full bottom-0 z-50" />}
              renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '10px' }} className="verticalTrack h-full right-0 z-50" />}
              renderThumbHorizontal={({ style, ...props}) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
              renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
          >
            <div className="px-2 py-4">
              <h1 className="text-4xl font-bold text-magenta-800 tracking-wider mb-4">{pageData.title}</h1>
              <div className="flex gap-2 transition-colors duration-250 text-gray-900 hover:text-gray-400">
                <span title={pageData.dateGmt.toLocaleString()} className="transition-colors hover:text-magenta-500"><FaClock className="w-4 h-4 inline-flex" /></span>
                <span title={`Tags: ${pageData.tags.join("; ")}`} className="transition-colors hover:text-magenta-500"><FaTags className="w-4 h-4 inline-flex" /></span>
              </div>
              <hr className="my-2 border-t-2 border-magenta-200 border-opacity-50" />
              {pageData?.highlights?.length ? (
                  <React.Fragment>
                    <h3 className="font-bold">Highlights:</h3>
                    <ul>
                      {pageData.highlights.map((highlight) => <li className="flex items-center ml-2"><FaHandPointRight className="w-4 h-4 mr-1 inline-flex" /> {highlight.text}</li>)}
                    </ul>
                    <hr className="my-2 border-t-2 border-magenta-200 border-opacity-50" />
                  </React.Fragment>
              ) : null}
              <p>{pageData.description}</p>
              <hr className="my-2 border-t-2 border-magenta-200 border-opacity-50" />
              {pageData?.links?.length ? (
                  <React.Fragment>
                    <h3 className="font-bold">Links:</h3>
                    <ul>
                      {pageData.links.map((link) => <li className="flex items-center ml-2"><FaExternalLinkAlt className="w-4 h-4 mr-1 inline-flex" /><a title={link.title} href={link.link} target="_blank" rel="noreferrer" className="transition-colors duration-250 hover:text-magenta-500">{link.title}</a></li>)}
                    </ul>
                  </React.Fragment>
              ) : null }
            </div>
          </Scrollbar>
        </div>
      </Transition>
  );
}

export default PortfolioSingle;