import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setBaseData } from "./redux/reducers/baseData";

import { useQuery } from "@apollo/client";
import GetBaseData from "./graphQL/queries/GetBaseData";

import Sidebar from "./Fragments/Sidebar";
import ResolveContent from "./Fragments/ResolveContent";
import PortfolioSingle from "./Fragments/Layout/PortfolioSingle";

// TODO redo how routing works to be able to use onload animations
function App() {
  const { /*loading, error,*/ data } = useQuery(GetBaseData);
  const portfolioItem = useSelector(({ primaryMenu }) => primaryMenu.portfolioItem);
  
  const dispatch = useDispatch();
  const location = useLocation();
  
  useEffect(() => {
    if(data?.generalSettings) {
      dispatch(setBaseData(data.generalSettings));
    }
  }, [data?.generalSettings]);
  
  return (
    <React.Fragment>
      <Sidebar />
      <div className="flex w-full relative">
        <div className="absolute top-0 left-0 w-full h-full bg-pageBackground1 bg-cover bg-center"></div>
        <div className="flex w-full relative">
          <ResolveContent uri={location.pathname} />
          {/*<Routes>
            <Route path="*" element={} />
            { TODO TEST n MODIFY to work on webserver correctly! }
          </Routes>*/}
        </div>
      </div>

      {portfolioItem ? <PortfolioSingle uri={location.pathname} /> : null}
    </React.Fragment>
  );
}

export default App;
