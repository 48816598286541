import React, { useEffect } from 'react';
import { useQuery } from "@apollo/client";

import GetSocialMenu from "../../graphQL/queries/GetSocialMenu";

import { FaDocker, FaTwitter, FaFacebook, FaGooglePlay, FaInstagram, FaCoffee, FaReddit, FaGithub, FaLinkedin, FaPatreon } from 'react-icons/fa';

function SocialMenu({ onLoaded }) {
  const { loading/*, error*/, data, startPolling } = useQuery(GetSocialMenu);
  
  useEffect(() => {
    if(data) {
      if(onLoaded) onLoaded();
      startPolling(60e3);
    }
  }, [data]);
  
  return (
    <div className="flex justify-center">
      {loading ? (<div>Loading...</div>) : null}
      {data?.menu?.menuItems?.nodes ? (
        <ul className="grid grid-cols-5 gap-4">
          {data.menu.menuItems.nodes.map((item, index) => {
            const iconClass = "w-6 h-6";
            let icon;
            let iconKey = item?.extra?.socialicon;
            let iconAlt = null;
            
            switch (iconKey) {
              case 'bmacoffee': {
                icon = <FaCoffee className={iconClass} />;
                iconAlt = "Buy Me A Coffee";
                break;
              }
              case 'docker': {
                icon = <FaDocker className={iconClass} />;
                iconAlt = "Docker";
                break;
              }
              case 'facebook': {
                icon = <FaFacebook className={iconClass} />;
                iconAlt = "Facebook";
                break;
              }
              case 'github': {
                icon = <FaGithub className={iconClass} />;
                iconAlt = "Github";
                break;
              }
              case 'google': {
                icon = <FaGooglePlay className={iconClass} />;
                iconAlt = "Google Play";
                break;
              }
              case 'instagram': {
                icon = <FaInstagram className={iconClass} />;
                iconAlt = "Instagram";
                break;
              }
              case 'linkedin': {
                icon = <FaLinkedin className={iconClass} />;
                iconAlt = "LinkedIN";
                break;
              }
              case 'patreon': {
                icon = <FaPatreon className={iconClass} />;
                iconAlt = "Patreon";
                break;
              }
              case 'reddit': {
                icon = <FaReddit className={iconClass} />;
                iconAlt = "Reddit";
                break;
              }
              case 'twitter': {
                icon = <FaTwitter className={iconClass} />;
                iconAlt = "Twitter";
                break;
              }
              default: {
                icon = <div>Icon type "{iconKey}" not available!</div>;
                break;
              }
            }
            
            return (<li key={index}><a title={iconAlt} href={item.url} target="_blank" rel="noreferrer" className="transition-colors duration-250 hover:text-magenta-500">{icon}</a></li>);
          })}
        </ul>
      ) : null}
    </div>
  );
}

export default SocialMenu;