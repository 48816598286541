import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import GetPage from "../../graphQL/queries/page/GetPage";

import constructPage from "../../utils/constructPage";

import PageNotFound from "../pageNotFound";
import Scrollbar from "react-custom-scrollbars-2";

function Page({ uri }) {
  const [getPage, { loading, error, data, startPolling }] = useLazyQuery(GetPage);
  
  useEffect(() => {
    if(uri) {
      getPage({
        variables: {
          id: uri
        }
      });
    }
  }, [uri]);
  
  useEffect(() => {
    if(data) {
      //console.log('data', data);
      startPolling(5 * 60e3);
    }
  }, [data]);
  
  if(loading) return (<div>Loading...</div>);
  if(data?.page === null) return (<PageNotFound />);
  
  return data?.page ? (
    <Scrollbar
      className="tracksOnHover"
      renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, height: '10px' }} className="horizontalTrack w-full bottom-0 z-50" />}
      renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '10px' }} className="verticalTrack h-full right-0 z-50" />}
      renderThumbHorizontal={({ style, ...props}) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
      renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
    >
      {constructPage(data.page)}
    </Scrollbar>
  ) : null;
}

export default Page;