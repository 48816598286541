import React, { useEffect, useState } from "react";

import { FaFilter } from 'react-icons/fa';

// TODO add ability to make it sticky!
function FilterBar({ items, onSelected }) {
  const [selected, setSelected] = useState({});
  
  const filterSelect = (group, key) => {
    setSelected((prevState) => {
      let newState = {...prevState};
      newState[group] = [];
      if(prevState?.[group]) newState[group] = [...prevState[group]];
      
      if(!newState[group].includes(key)) {
        newState[group].push(key);
      } else {
        newState[group].splice(newState[group].indexOf(key), 1);
        if(newState[group].length === 0) delete newState[group];
      }
      
      return newState;
    });
  }
  
  useEffect(() => {
    if(onSelected) onSelected(selected);
  }, [selected]);
  
  return (
    <div className={`flex flex-col absolute top-0 inset-x-0 px-4 py-2 bg-neutral-300 border-b-10 border-magenta-500 z-10 transition-transform ${Object.keys(items).length ? '-translate-y-border' : '-translate-y-full'} hover:translate-y-0`}>
      <div className="flex flex-col relative">
        {Object.keys(items).length ? Object.keys(items).sort().map((groupKey) => {
    
          return (
            <div
              key={groupKey}
              className="mb-4 last:mb-0"
            >
              <div className="mb-2 font-bold uppercase text-xl">
                {items[groupKey].title}
              </div>
              <div className="">
                {Object.keys(items[groupKey].items).map((groupItemKey) => {
                  return items[groupKey].items[groupItemKey].visible ? (
                    <button
                      key={`${groupKey}_${groupItemKey}`}
                      className={`py-1 px-2 mb-2 mr-2 transition-colors duration-250 border-1 ${selected?.[groupKey]?.includes(groupItemKey) ? 'border-magenta-500' : 'border-neutral-500'} hover:border-magenta-700 ${selected?.[groupKey]?.includes(groupItemKey) ? 'bg-magenta-200 hover:bg-magenta-400' : 'bg-neutral-200 hover:bg-magenta-200'}`}
                      onClick={() => filterSelect(groupKey, groupItemKey)}
                    >
                      {items[groupKey].items[groupItemKey].title}
                    </button>
                  ) : null;
                })}
              </div>
            </div>
          );
        }) : null}
      </div>
      <div className={`flex absolute ${Object.keys(items).length ? 'top-full' : '-top-full'} px-2.5 py-2 bg-magenta-500 z-10 right-10`}>
        <FaFilter className="w-4 h-4 text-white" />
      </div>
    </div>
  );
}

export default FilterBar;