import { gql } from "@apollo/client";
import author from "./../author";
import mediaItem from "./../mediaItem";

export default gql`
fragment pageSummaryFragment on Page {
  id
  date
  dateGmt
  author {
    node {
      ...authorFragment
    }
  }
  featuredImage {
    node {
      ...mediaItemFragment
    }
  }
  pageExtra {
    pagestyle
  }
  title
  slug
  uri
}
${author}
${mediaItem}
`;