import { gql } from "@apollo/client";

export default gql`
fragment pageContentFragment on Page {
  blocks {
    name
    order
    attributesJSON
    innerBlocks {
      name
      order
      attributesJSON
      innerBlocks {
        name
        order
        attributesJSON
        innerBlocks {
          name
          order
          attributesJSON
          innerBlocks {
            name
            order
            attributesJSON
            innerBlocks {
              name
              order
              attributesJSON
            }
          }
        }
      }
    }
  }
}
`;