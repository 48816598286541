import { gql } from "@apollo/client";

export default gql`
fragment menuFields on MenuItem {
  id
  order
  title
  label
  target
  pageType
  postType
  uri
  url
  extra {
    socialicon
  }
}
`;