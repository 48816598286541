import React, { useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";
import GetPageType from "../graphQL/queries/GetPageType";

import Page from "./Layout/Page";
import Post from "./Layout/Post";

import PortfolioArchive from "./Layout/PortfolioArchive";

import PageNotFound from "./pageNotFound";

function ResolveContent({ uri }) {
  const [getPageType] = useLazyQuery(GetPageType);
  
  const [pageType, setPageType] = useState(null);
  
  useEffect(() => {
    getPageType({
      variables: { uri: uri }
    }).then((response) => {
      if(response?.data?.pageType) setPageType(response.data.pageType);
    })
  }, [uri]);
  
  useEffect(() => {
    console.log('pageType', pageType);
  }, [pageType]);
  
  if(pageType !== null) {
    switch (pageType) {
      case 'page': {
        return <Page uri={uri} />;
      }
      case 'post': {
        return <Post uri={uri} />;
      }
      case 'portfolioArchive': {
        return <PortfolioArchive />;
      }
      case 'portfolio': {
        return <PortfolioArchive />;
      }
      default: {
        return <PageNotFound />;
        //return <div>{pageType}</div>;
      }
    }
  }
  return null;
}

export default ResolveContent;