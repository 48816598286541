import { gql } from "@apollo/client";
import author from "../../fragments/author";
import mediaItem from "../../fragments/mediaItem";

export default gql`
query GetPost($id: ID = "") {
  post(id: $id, idType: URI) {
    id
    date
    dateGmt
    author {
      node {
        ...authorFragment
      }
    }
    featuredImage {
      node {
        ...mediaItemFragment
      }
    }
    title
    slug
    uri
    
    blocks {
      name
      order
      attributesJSON
      innerBlocks {
        name
        order
        attributesJSON
        innerBlocks {
          name
          order
          attributesJSON
          innerBlocks {
            name
            order
            attributesJSON
            innerBlocks {
              name
              order
              attributesJSON
              innerBlocks {
                name
                order
                attributesJSON
              }
            }
          }
        }
      }
    }
  }
}
${author}
${mediaItem}
`;