import { gql } from "@apollo/client";
import author from '../../fragments/author';
import mediaItem from "../../fragments/mediaItem";

export default gql`
fragment portfolioSummary on PortfolioItem {
  id
  date
  dateGmt
  author {
    node {
      ...authorFragment
    }
  }
  featuredImage {
    node {
      ...mediaItemFragment
    }
  }
  portfolioTags {
    nodes {
      id
      name
      slug
    }
  }
  projectFulfillmentDate
  projectCategory
  title
  projectExcerpt
  slug
  uri
}
${author}
${mediaItem}
`;