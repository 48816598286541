import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import './index.css';
import App from './App';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import { getCLS, getFID, getLCP } from 'web-vitals';

const GBBlock = {
  fields: {
    attributesJSON: {
      read(attributesJSON) {
        try {
          return JSON.parse(attributesJSON);
        } catch (e) {
          return attributesJSON;
        }
      },
      merge(existing, incoming, { mergeObjects }) {
        try {
          return JSON.stringify(mergeObjects(JSON.parse(existing), JSON.parse(incoming)));
        } catch (e) {
          return mergeObjects(existing, incoming);
        }
      }
    }
  }
};

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache({
    typePolicies: {
      CoreParagraphBlock: GBBlock,
      CoreImageBlock: GBBlock,
      CoreHeadingBlock: GBBlock,
      CoreGalleryBlock: GBBlock,
      CoreListBlock: GBBlock,
      CoreQuoteBlock: GBBlock,
      CoreArchivesBlock: GBBlock,
      CoreAudioBlock: GBBlock,
      CoreButtonBlock: GBBlock,
      CoreButtonsBlock: GBBlock,
      CoreCalendarBlock: GBBlock,
      CoreCategoriesBlock: GBBlock,
      CoreFreeformBlock: GBBlock,
      CoreCodeBlock: GBBlock,
      CoreColumnBlock: GBBlock,
      CoreColumnsBlock: GBBlock,
      CoreCoverBlock: GBBlock,
      CoreEmbedBlock: GBBlock,
      CoreFileBlock: GBBlock,
      CoreGroupBlock: GBBlock,
      CoreHtmlBlock: GBBlock,
      CoreLatestCommentsBlock: GBBlock,
      CoreLatestPostsBlock: GBBlock,
      CoreMediaTextBlock: GBBlock,
      CoreMissingBlock: GBBlock,
      CoreMoreBlock: GBBlock,
      CoreNextpageBlock: GBBlock,
      CorePageListBlock: GBBlock,
      CorePatternBlock: GBBlock,
      CorePreformattedBlock: GBBlock,
      CorePullquoteBlock: GBBlock,
      CoreBlock: GBBlock,
      CoreRssBlock: GBBlock,
      CoreSearchBlock: GBBlock,
      CoreSeparatorBlock: GBBlock,
      CoreShortcodeBlock: GBBlock,
      CoreSocialLinkBlock: GBBlock,
      CoreSocialLinksBlock: GBBlock,
      CoreSpacerBlock: GBBlock,
      CoreTableBlock: GBBlock,
      CoreTagCloudBlock: GBBlock,
      CoreTextColumnsBlock: GBBlock,
      CoreVerseBlock: GBBlock,
      CoreVideoBlock: GBBlock,
      CoreNavigationBlock: GBBlock,
      CoreNavigationLinkBlock: GBBlock,
      CoreNavigationSubmenuBlock: GBBlock,
      CoreSiteLogoBlock: GBBlock,
      CoreSiteTitleBlock: GBBlock,
      CoreSiteTaglineBlock: GBBlock,
      CoreQueryBlock: GBBlock,
      CoreTemplatePartBlock: GBBlock,
      CoreAvatarBlock: GBBlock,
      CorePostTitleBlock: GBBlock,
      CorePostExcerptBlock: GBBlock,
      CorePostFeaturedImageBlock: GBBlock,
      CorePostContentBlock: GBBlock,
      CorePostAuthorBlock: GBBlock,
      CorePostDateBlock: GBBlock,
      CorePostTermsBlock: GBBlock,
      CorePostNavigationLinkBlock: GBBlock,
      CorePostTemplateBlock: GBBlock,
      CoreQueryPaginationBlock: GBBlock,
      CoreQueryPaginationNextBlock: GBBlock,
      CoreQueryPaginationNumbersBlock: GBBlock,
      CoreQueryPaginationPreviousBlock: GBBlock,
      CoreQueryNoResultsBlock: GBBlock,
      CoreReadMoreBlock: GBBlock,
      CoreCommentAuthorNameBlock: GBBlock,
      CoreCommentContentBlock: GBBlock,
      CoreCommentDateBlock: GBBlock,
      CoreCommentEditLinkBlock: GBBlock,
      CoreCommentReplyLinkBlock: GBBlock,
      CoreCommentTemplateBlock: GBBlock,
      CoreCommentsTitleBlock: GBBlock,
      CoreCommentsQueryLoopBlock: GBBlock,
      CoreCommentsPaginationBlock: GBBlock,
      CoreCommentsPaginationNextBlock: GBBlock,
      CoreCommentsPaginationNumbersBlock: GBBlock,
      CoreCommentsPaginationPreviousBlock: GBBlock,
      CorePostCommentsBlock: GBBlock,
      CorePostCommentsFormBlock: GBBlock,
      CoreHomeLinkBlock: GBBlock,
      CoreLoginoutBlock: GBBlock,
      CoreTermDescriptionBlock: GBBlock,
      CoreQueryTitleBlock: GBBlock,
      CorePostAuthorBiographyBlock: GBBlock
    }
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    }
  }
});

// TODO add proper responsiveness when appropriate
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO check this once built!
const gtagCheck = (data) => {
  if(window.gtag) {
    sendToGoogleAnalytics(data);
  } else {
    console.log('func not yet available, trying again in 100ms');
    setTimeout(gtagCheck, 100, data);
  }
}
const sendToGoogleAnalytics = ({name, delta, value, id}) => {
  window.gtag('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.
    
    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
}
getCLS(gtagCheck);
getFID(gtagCheck);
getLCP(gtagCheck);