import { gql } from "@apollo/client";
import menuFields from "../fragments/menuFields";

export default gql`
query GetSocialMenu {
  menu(id: "social", idType: LOCATION) {
    menuItems {
      nodes {
        ...menuFields
      }
    }
  }
}
${menuFields}
`;