import { gql } from "@apollo/client";

export default gql`
query GetPortfolioTags {
  portfolioTags(where: {hideEmpty: true}) {
    nodes {
      id
      name
      slug
    }
  }
}
`;