import { configureStore } from '@reduxjs/toolkit';

import baseDataReducer from './reducers/baseData';
import primaryMenuReducer from './reducers/primaryMenu';

export const store = configureStore({
  reducer: {
    baseData: baseDataReducer,
    primaryMenu: primaryMenuReducer
  }
});