import { gql } from "@apollo/client";
import mediaItem from "../fragments/mediaItem";

export default gql`
query GetMediaItems($ids: [ID] = []) {
  mediaItems(where: {in: $ids}) {
    nodes {
      mediaItemId
      ...mediaItemFragment
    }
  }
}
${mediaItem}
`;