import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: {
    id: "",
    uri: "",
    pgType: "",
    psType: ""
  },
  items: [],
  portfolioItem: false,
};

export const primaryMenuSlice = createSlice({
  name: 'primaryMenu',
  initialState,
  reducers: {
    setActive: (state, data) => {
      if(
          data?.payload?.id === undefined ||
          data?.payload?.uri === undefined ||
          data?.payload?.pgType === undefined ||
          data?.payload?.psType === undefined
      ) return;

      state.active = {
        id: data?.payload?.id,
        uri: data?.payload?.uri,
        pgType: data?.payload?.pgType,
        psType: data?.payload?.psType
      };
    },
    setItems: (state, data) => {
      state.items = data.payload;
    },
    toggleIsPortfolioItem: (state, data) => {
      state.portfolioItem = data?.payload ?? !state.portfolioItem;
    }
  }
});

export const {
  setActive, setItems,
  toggleIsPortfolioItem
} = primaryMenuSlice.actions;
export default primaryMenuSlice.reducer;