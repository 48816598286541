import { gql } from "@apollo/client";
import pageSummary from "../../fragments/page/pageSummary";
import pageContent from "../../fragments/page/pageContent";

export default gql`
query GetPage($id: ID = "/") {
  page(id: $id, idType: URI) {
    ...pageSummaryFragment
    ...pageContentFragment
  }
}
${pageSummary}
${pageContent}
`;