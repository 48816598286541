import { gql } from "@apollo/client";
import portfolioSummary from "../../fragments/portfolio/portfolioSummary";

export default gql`
query GetPortfolioArchive($after: String = "", $first: Int = 16) {
  portfolio(after: $after, first: $first) {
    nodes {
      ...portfolioSummary
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
${portfolioSummary}
`;