import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";

import GetPrimaryMenu from "../../graphQL/queries/GetPrimaryMenu";

import { FaPlus } from "react-icons/fa";
import {setItems, setActive, toggleIsPortfolioItem} from "../../redux/reducers/primaryMenu";

// TODO add some effects with react-transition-group
// TODO add proper loading skeleton with animations
function PrimaryMenu({ onLoaded }) {
  const { /*loading, error,*/ data, startPolling } = useQuery(GetPrimaryMenu);
  const [ menuActiveState, setMenuActiveState ] = useState({
    id: "",
    uri: "",
    pgType: "",
    psType: ""
  });
  
  const dispatch = useDispatch();
  const location = useLocation();
  const menuItems = useSelector(({ primaryMenu }) => primaryMenu.items);
  const menuActive = useSelector(({ primaryMenu }) => primaryMenu.active);
  
  const keyFlatMapper = (ele, key = 'id') => {
    if(ele?.children) {
      let childMap = ele.children.map((item) => keyFlatMapper(item, key));
      childMap.push(ele?.[key] || ele);
      return childMap;
    } else {
      return ele?.[key] || ele;
    }
  }
  
  const menuWalker = (arr, shouldReturn = false) => {
    let menuContent = [];
    for(const menuItem of arr) {
      if(menuContent.map((item) => keyFlatMapper(item, 'id')).flat(Infinity).includes(menuItem.id)) continue;

      if(
          (location.pathname !== menuActiveState.uri && location.pathname !== menuItem.uri && location.pathname.startsWith(menuItem.uri)) ||
          (location.pathname === menuItem.uri && location.pathname.startsWith(menuItem.uri))
      ) {
        setMenuActiveState((prevState) => {
          return {
            ...prevState,
            id: menuItem.id,
            uri: menuItem.uri,
            pgType: menuItem.pageType,
            psType: menuItem.postType
          }
        });
      }

      if(menuItem.childItems.nodes.length > 0) {
        menuContent.push({
          id: menuItem.id,
          title: menuItem.title,
          label: menuItem.label,
          pageType: menuItem.pageType,
          postType: menuItem.postType,
          target: menuItem.target,
          uri: menuItem.uri,
          url: menuItem.target ? menuItem.url : menuItem.uri,
          children: menuWalker(menuItem.childItems.nodes, true)
        });
      } else {
        menuContent.push({
          id: menuItem.id,
          title: menuItem.title,
          label: menuItem.label,
          pageType: menuItem.pageType,
          postType: menuItem.postType,
          target: menuItem.target,
          uri: menuItem.uri,
          url: menuItem.target ? menuItem.url : menuItem.uri
        });
      }
    }
    if(shouldReturn) {
      return menuContent;
    } else {
      dispatch(setItems(menuContent));
    }
  }
  const renderMenu = (menuItem, menuItemIndex, key, depth) => {
    let childActive = false;
    if(menuItem?.children) {
      childActive = menuItem.children.map((item) => keyFlatMapper(item, 'id')).flat(Infinity).includes(menuActive.id);
    }
    
    return (
      <div key={`${key}${menuItemIndex}`} className={`flex flex-col relative mb-1 group-scope1`}>
        <div className="absolute w-full h-full overflow-hidden">
          <div className={`absolute w-full h-full transform transition-transform duration-250 bg-magenta-500 ${menuActive.id === menuItem.id ? 'translate-x-0' : (childActive ? 'translate-x-icon' : 'translate-x-full')} group-scope1-hover:translate-x-0`}></div>
        </div>
        <div className="flex justify-between items-center pl-2 py-2 z-10">
          {menuItem.target ? (
            <a href={menuItem.url} className="w-full" target="_blank" rel="noreferrer">{menuItem.title ? menuItem.title : menuItem.label}</a>
          ) : (
            <NavLink to={menuItem.url} className="w-full" onClick={() => setMenuActiveState((prevState) => {
              return {
                ...prevState,
                id: menuItem.id,
                uri: menuItem.uri,
                pgType: menuItem.pageType,
                psType: menuItem.postType
              };
            })}>{menuItem.title ? menuItem.title : menuItem.label}</NavLink>
          )}
          {menuItem?.children ? <div className="px-2"><FaPlus className="w-4 h-4" /></div> : null}
        </div>
        {menuItem?.children ? (<div className={`hidden flex-col absolute left-full w-full pl-[10px] group-scope1-hover:flex`}>{menuItem.children.map((item, index) => renderChildren(item, index, `${key}${menuItemIndex}Child`, (depth+1)))}</div>) : null}
      </div>
    );
  }
  const renderChildren = (menuItem, menuItemIndex, key, depth) => {
    let scoped = "";
    let scopedHoverTranslate = "";
    let scopedHoverFlex = "";
    
    switch (depth) {
      default:
      case 1:
        scoped = 'group-scope1';
        scopedHoverTranslate = 'group-scope1-hover:translate-x-0';
        scopedHoverFlex = 'group-scope1-hover:flex';
        break;
      case 2:
        scoped = 'group-scope2';
        scopedHoverTranslate = 'group-scope2-hover:translate-x-0';
        scopedHoverFlex = 'group-scope2-hover:flex';
        break;
      case 3:
        scoped = 'group-scope3';
        scopedHoverTranslate = 'group-scope3-hover:translate-x-0';
        scopedHoverFlex = 'group-scope3-hover:flex';
        break;
      case 4:
        scoped = 'group-scope4';
        scopedHoverTranslate = 'group-scope4-hover:translate-x-0';
        scopedHoverFlex = 'group-scope4-hover:flex';
        break;
      case 5:
        scoped = 'group-scope5';
        scopedHoverTranslate = 'group-scope5-hover:translate-x-0';
        scopedHoverFlex = 'group-scope5-hover:flex';
        break;
    }
  
    let childActive = false;
    if(menuItem?.children) {
      childActive = menuItem.children.map((item) => keyFlatMapper(item, 'id')).flat(Infinity).includes(menuActive.id);
    }
    
    return (
      <React.Fragment key={`${key}${menuItemIndex}`}>
        <div className="absolute w-full h-full sidebarBackground"></div>
        <div key={`${key}${menuItemIndex}`} className={`flex flex-col relative ${depth === 2 ? "mr-[-10px]" : 'mr-0'} ${scoped}`}>
          <div className="absolute w-full h-full overflow-hidden z-10">
            <div className={`absolute w-full h-full transform transition-transform duration-250 bg-magenta-500 ${menuActive.id === menuItem.id ? 'translate-x-0' : (childActive ? 'translate-x-icon' : '-translate-x-full')} ${scopedHoverTranslate}`}></div>
          </div>
          <div className="flex justify-between items-center pl-2 py-2 z-10">
            {menuItem.target ? (
              <a href={menuItem.url} className="w-full" target="_blank" rel="noreferrer">{menuItem.title ? menuItem.title : menuItem.label}</a>
            ) : (
              <NavLink to={menuItem.url} className="w-full" onClick={() => setMenuActiveState((prevState) => {
                return {
                  ...prevState,
                  id: menuItem.id,
                  uri: menuItem.uri,
                  pgType: menuItem.pageType,
                  psType: menuItem.postType
                };
              })}>{menuItem.title ? menuItem.title : menuItem.label}</NavLink>
            )}
            {menuItem?.children ? <div className="px-2"><FaPlus className="w-4 h-4" /></div> : null}
          </div>
          {menuItem?.children ? (<div className={`hidden flex-col absolute left-full w-full ${scopedHoverFlex}`}>{menuItem.children.map((item, index) => renderChildren(item, index, `${key}${menuItemIndex}Child`, (depth+1)))}</div>) : null}
        </div>
      </React.Fragment>
    );
  }
  
  useEffect(() => {
    if(data?.menu?.menuItems?.nodes) {
      menuWalker(data.menu.menuItems.nodes);
    }
  }, [data]);
  
  useEffect(() => {
    if(menuItems.length > 0) {
      if(onLoaded) onLoaded();
      startPolling(15e3);
    }
  }, [menuItems]);

  useEffect(() => {
    dispatch(setActive(menuActiveState));
  }, [menuActiveState]);

  useEffect(() => {
    if(location.pathname === "/") {
      setMenuActiveState((prevState) => {
        return {
          ...prevState,
          id: "",
          uri: "",
          pgType: "",
          psType: ""
        };
      });
      dispatch(toggleIsPortfolioItem(false));
    } else {
      // Portfolio Item Overlay
      if(
          location.pathname.toLowerCase().includes("portfolio") &&
          location.pathname.split("/").filter((ele) => ele !== "").length > 1 &&
          location.pathname !== menuActiveState.uri &&
          location.pathname.startsWith(menuActiveState.uri)
      ) {
        dispatch(toggleIsPortfolioItem(true));
      }

      if(
          location.pathname.toLowerCase().includes("portfolio") &&
          location.pathname.split("/").filter((ele) => ele !== "").length === 1 &&
          location.pathname === menuActiveState.uri &&
          location.pathname.startsWith(menuActiveState.uri)
      ) {
        dispatch(toggleIsPortfolioItem(false));
      }
    }
  }, [location.pathname]);
  
  return (
    <div className="flex flex-col pl-4">
      {menuItems.length ? menuItems.map((item, index) => renderMenu(item, index, 'root', 1)) : (<div>Loading...</div>)}
    </div>
  );
}

export default PrimaryMenu;