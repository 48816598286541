import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  site: {
    title: "",
    description: "",
    url: ""
  },
  sidebar: {
    loaded: false,
    expanded: true,
    logoAnimate: false,
    ready: {
      primaryMenu: false,
      socialMenu: false
    }
  }
};

export const baseDataSlice = createSlice({
  name: 'baseData',
  initialState,
  reducers: {
    setBaseData: (state, data) => {
      for(const key in data.payload) {
        if(key === "__typename") continue;
        
        state.site[key] = data.payload[key];
      }
    },
    
    // Sidebar Related
    setSidebarLoaded: (state, data) => {
      if(data.payload === undefined) {
        state.sidebar.loaded = true;
      } else {
        state.sidebar.loaded = data.payload;
      }
    },
    setSidebarExpanded: (state, data) => {
      if(data.payload === undefined) return;
      state.sidebar.expanded = data.payload;
    },
    toggleSidebarExpanded: (state) => {
      state.sidebar.expanded = !state.sidebar.expanded;
    },
    setLogoAnimate: (state, data) => {
      if(data.payload === undefined) {
        state.sidebar.logoAnimate = true;
      } else {
        state.sidebar.logoAnimate = data.payload;
      }
    },
    setSidebarReady: (state, data) => {
      if(data.payload === undefined || data.payload.key === undefined) return;
      if(data.payload.value === undefined) {
        state.sidebar.ready[data.payload.key] = true;
      } else {
        state.sidebar.ready[data.payload.key] = data.payload.value;
      }
    },
    
    // Other...
  }
});

export const {
  setBaseData,
  setSidebarLoaded, setSidebarExpanded, toggleSidebarExpanded,
  setLogoAnimate,
  setSidebarReady
} = baseDataSlice.actions;
export default baseDataSlice.reducer;