import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import GetPortfolioTags from "../../graphQL/queries/portfolio/GetPortfolioTags";
import GetPortfolioArchive from "../../graphQL/queries/portfolio/GetPortfolioArchive";
import GetPortfolioCategories from "../../graphQL/queries/portfolio/GetPortfolioCategories";
import { Waypoint } from 'react-waypoint';
import FilterBar from "../../Components/FilterBar";
import Image from "../../Components/Image";
import Scrollbar from "react-custom-scrollbars-2";

const colCount = 4;
const colCountClass = 'grid-cols-4';

// TODO portfolioItem on load, filter animations
function PortfolioArchive() {
  const [getPortfolioTags] = useLazyQuery(GetPortfolioTags, {onError: (err) => {console.log('getPortfolioTags', err)}});
  const [getPortfolioArchive] = useLazyQuery(GetPortfolioArchive, {onError: (err) => {console.log('getPortfolioArchive', err)}});
  const [getPortfolioCategories] = useLazyQuery(GetPortfolioCategories, {onError: (err) => {console.log('getPortfolioCategories', err)}});
  
  const [filterItems, setFilterItems] = useState({});
  const [filterSelection, setFilterSelection] = useState({});
  const [items, setItems] = useState([]);
  
  const [nextCursor, setNextCursor] = useState("");
  const [endReached, setEndReached] = useState(true);
  
  const getFilterSelectionKey = (filterKey, value) => {
    switch (filterKey) {
      case "category": {
        if(filterSelection?.category) {
          return filterSelection.category.includes(value);
        }
        return false;
      }
      case "tag": {
        if(filterSelection?.tag) {
          if(value.length) {
            let _visible = false;
            for(const projectTag of value.map((item) => item.id)) {
              if(!_visible) _visible = filterSelection.tag.includes(projectTag);
            }
            return _visible;
          }
        }
        return false;
      }
      default: {
        return false;
      }
    }
  }

  const processPortfolioArchive = (result) => {
    // process data
    if(result?.data?.portfolio?.nodes) {
      setItems((prevState) => {
        let newChunk = result.data.portfolio.nodes.map((item) => {
          let _item = {...item};
          _item['visible'] = true;

          // Category Filter
          if(_item?.projectCategory && filterSelection?.category) {
            _item['visible'] = getFilterSelectionKey('category', _item.projectCategory) && _item['visible'];
          }

          // Tags Filter
          if(_item?.portfolioTags?.nodes.length && filterSelection?.tag) {
            _item['visible'] = getFilterSelectionKey('tag', _item.portfolioTags.nodes) && _item['visible'];
          }

          return _item;
        });

        return [...prevState].concat(newChunk);
      });
    }

    if(result?.data?.portfolio?.pageInfo) {
      if(result.data.portfolio.pageInfo.hasNextPage) {
        setNextCursor((prevState) => result.data.portfolio.pageInfo.endCursor);
        setEndReached((prevState) => false);
      } else {
        setNextCursor((prevState) => "");

        if(result.data.portfolio.pageInfo.hasPreviousPage) {
          console.log(result.data);
          setEndReached((prevState) => true);
        }
      }
    }
  }

  const renderMore = () => {
    if(endReached) return;

    getPortfolioArchive({ variables: { after: nextCursor, first: 16 } }).then(processPortfolioArchive);
  }
  
  useEffect(() => {
    getPortfolioTags().then((result) => {
      //console.log("getPortfolioTags()", result);
      if(result?.data?.portfolioTags?.nodes) {
        let portfolioTags = {};
        
        for(const tag of result.data.portfolioTags.nodes) {
          portfolioTags[tag.id] = {
            title: tag.name,
            visible: false
          };
        }
        
        setFilterItems((prevState) => {
          return {
            ...prevState,
            'tag': {
              'title': "Tags",
              'items': portfolioTags
            }
          }
        });
      }
    });
    
    getPortfolioCategories().then((result) => {
      //console.log("getPortfolioCategories()", result);
      if(result?.data?.portfolioCategoryJSON) {
        try {
          let _items = JSON.parse(result.data.portfolioCategoryJSON);
          
          for(const key in _items) {
            _items[key] = {
              title: _items[key],
              visible: false
            };
          }
          
          setFilterItems((prevState) => {
            return {
              ...prevState,
              'category': {
                'title': "Categories",
                'items': _items
              }
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    });

    getPortfolioArchive({ variables: { after: nextCursor, first: 32 } }).then(processPortfolioArchive);
  }, []);
  
  useEffect(() => {
    if(!items.length) return;
    
    let currentFilters = {...filterItems};
    for(const _item of items) {
      // Show Categories
      if(_item.projectCategory && !currentFilters?.category?.items?.[_item.projectCategory]?.visible) {
        currentFilters.category.items[_item.projectCategory].visible = true;
      }
      
      // Tags
      if(_item?.portfolioTags?.nodes.length) {
        for(const _tag of _item.portfolioTags.nodes) {
          if(!currentFilters?.tag?.items?.[_tag.id]?.visible) {
            currentFilters.tag.items[_tag.id].visible = true;
          }
        }
      }
    }
    setFilterItems((prevState) => currentFilters);
  }, [items]);
  
  useEffect(() => {
    if(Object.keys(filterSelection).length) {
      /* Apply Filters */
      setItems((prevState) => {
        let newState = [...prevState];
        newState.map((item, index) => {
          newState[index] = {...item};
          let visible = true;
        
          // Category Filter
          if(newState[index]?.projectCategory && filterSelection?.category) {
            visible = getFilterSelectionKey('category', newState[index].projectCategory) && visible;
          }
        
          // Tags Filter
          if(newState[index]?.portfolioTags?.nodes.length && filterSelection?.tag) {
            visible = getFilterSelectionKey('tag', newState[index].portfolioTags.nodes) && visible;
          }
        
          newState[index]['visible'] = visible;
        });
        return newState;
      });
    } else {
      /* Reset Filters */
      setItems((prevState) => {
        let newState = [...prevState];
        newState.map((item, index) => {
          newState[index] = {
            ...item,
            visible: true
          };
        });
        return newState;
      });
    }
  }, [filterSelection]);
  
  return (
    <div className="flex flex-col w-full relative">
      <FilterBar items={filterItems} onSelected={setFilterSelection} />
      <Scrollbar
        className="tracksOnHover"
        renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, height: '10px' }} className="horizontalTrack w-full bottom-0 z-50" />}
        renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '10px' }} className="verticalTrack h-full right-0 z-50" />}
        renderThumbHorizontal={({ style, ...props}) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
        renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="bg-magenta-500" />}
      >
        <div className={`grid ${colCountClass} relative pt-[10px] z-5`}>
          {items.length ? items.map((item) => {
            if(!item?.featuredImage?.node || !item.visible) return;
      
            const html = (
              <React.Fragment>
                <Image noTitle {...item.featuredImage.node} sizes={`${100/colCount}vw`} />
                <div className="flex flex-col justify-between items-center absolute inset-0 transition-transform duration-250 translate-y-full bg-magenta-200 bg-opacity-60 group-hover:translate-y-0">
                  <h2 className="text-xl p-2">{item.title}</h2>
                  {item.projectExcerpt ? <div>{item.projectExcerpt}</div> : null}
                  {item.projectCategory && filterItems?.category?.items?.[item.projectCategory] ? <div className="text-sm p-2">{filterItems?.category?.items?.[item.projectCategory].title}</div> : null}
                </div>
              </React.Fragment>
            );
      
            return (
              <NavLink
                to={item.uri}
                key={item.id}
                title={item.title}
                className="flex flex-col relative overflow-hidden font-bold group"
              >
                {html}
              </NavLink>
            );
            /*return (
              <button
                key={item.id}
                title={item.title}
                className="flex flex-col relative overflow-hidden font-bold group"
                onClick={() => {console.log(`Show Project: ${item.id}`)}}
              >
                {html}
              </button>
            );*/
          }) : (<div>Loading...</div>)}
          {!endReached ? (
            <Waypoint onEnter={renderMore}>
              <div className="absolute bottom-0 w-full h-64"></div>
            </Waypoint>
          ) : null}
        </div>
      </Scrollbar>
    </div>
  );
}

export default PortfolioArchive;