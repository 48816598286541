import { gql } from "@apollo/client";
import portfolioSummary from "../../fragments/portfolio/portfolioSummary";
import portfolioContent from "../../fragments/portfolio/portfolioContent";

export default gql`
query GetPortfolioItem($id: ID = "") {
  portfolioItem(id: $id, idType: URI) {
    ...portfolioSummary
    ...portfolioContent
  }
}
${portfolioSummary}
${portfolioContent}
`;